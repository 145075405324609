<template>
  <div class="card">
      <div class="card__bg" ref="bg"></div>
      <div class="card__header" >
          <CardDigit :digit="digit" ref="digit"></CardDigit>
          <div class="card__bar" ref="bar"></div>
      </div>
      <div class="card__content" ref="content">
        <slot></slot>
      </div>
  </div>
</template>

<script>
import CardDigit from '@/components/CardDigit'
import gsap from 'gsap'
export default {
  name: 'Card',
  components: { CardDigit },
  props: {
    digit: {
      default: '01'
    }
  },
  methods: {
    hide () {
      /* gsap.to(this.$refs.bg, { duration: 1, y: -50, ease: 'Sine.easeOut' })
      gsap.to(this.$refs.bar, { duration: 0.5, y: -100, ease: 'Sine.easeOut' }) */
      gsap.to(this.$refs.content, { overwrite: true, duration: 0.15, autoAlpha: 0 })
      this.$refs.digit.hide()
    },
    show () {
      gsap.to(this.$refs.bg, { duration: 0, transformOrigin: '0 0', scaleX: 1, ease: 'Expo.easeOut' })
      gsap.to(this.$refs.bar, { duration: 0, transformOrigin: '0 0', scaleX: 1, ease: 'Expo.easeOut' })
      gsap.to(this.$refs.content, { duration: 0, autoAlpha: 0 })
      gsap.to(this.$refs.digit.$el, { duration: 0, x: 0 })

      gsap.to(this.$refs.bg, { duration: 0.5, transformOrigin: '0 0', scaleX: 1, ease: 'Expo.easeOut' })
      gsap.to(this.$refs.bar, { duration: 1, transformOrigin: '0 0', scaleX: 1, ease: 'Expo.easeOut' })
      gsap.to(this.$refs.content, { duration: 1, delay: 0.25, autoAlpha: 1 })
      gsap.to(this.$refs.digit.$el, { duration: 1, x: 0 })
    }
  }
}
</script>
