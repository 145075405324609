
<template>
  <div class="card__digit"  v-view="viewHandler">
      <div ref="slot1" class="card__digit--slot">
        <h5>0</h5>
        <h5>1</h5>
        <h5>2</h5>
        <h5>3</h5>
        <h5>4</h5>
        <h5>5</h5>
        <h5>6</h5>
        <h5>7</h5>
        <h5>8</h5>
        <h5>9</h5>
      </div>

      <div ref="slot2" class="card__digit--slot">
        <h5>0</h5>
        <h5>1</h5>
        <h5>2</h5>
        <h5>3</h5>
        <h5>4</h5>
        <h5>5</h5>
        <h5>6</h5>
        <h5>7</h5>
        <h5>8</h5>
        <h5>9</h5>
      </div>
    </div>
</template>

<script>
import gsap from 'gsap'
export default {
  name: 'CardDigit',
  props: {
    digit: undefined
  },

  data () {
    return {
      shown: false
    }
  },
  watch: {
    digit () {
      this.updateDigit()
    }
  },
  methods: {
    updateDigit () {
      const targets = [9, 9]

      targets.forEach((digit, index) => {
        const offset = digit * 90
        gsap.to(this.$refs['slot' + (index + 1)], { overwrite: true, duration: 0, y: -offset, ease: 'Expo.easeOut' })
      })
    },
    hide () {
      const targets = [9, 9]
      targets.forEach((digit, index) => {
        const offset = digit * 90
        gsap.to(this.$refs['slot' + (index + 1)], { overwrite: true, duration: 1, y: -offset, ease: 'Expo.easeOut' })
      })
    },
    show () {
      if (this.shown) return
      this.shown = true
      const targets = this.digit.split('')

      targets.forEach((digit, index) => {
        const offset = digit * 90
        let duration = Math.max((offset + 1) * 0.1, 1)
        duration = Math.min(duration, 2)
        duration = 0.5 + Math.random() * 0.5
        gsap.to(this.$refs['slot' + (index + 1)], { overwrite: true, duration: duration, y: -offset, ease: 'Expo.easeOut' })
      })
    },
    viewHandler (e) {
      if (e.type === 'enter') {
        gsap.delayedCall(0.01, this.show)
      }
    }
  },
  beforeMount () {
  },
  mounted () {
    // const targets = [Math.floor(9 * Math.random()), Math.floor(9 * Math.random())]
    this.updateDigit()
  }

}
</script>
