<template>
  <div class="ticker-headline" ref="container" v-view.once="viewHandler">
    <h2 :class="{'cursor-hidden':!cursorVisible}" ref="headline">{{currentText}}</h2>
    <h2 class="hidden" ref="reference">{{text}}</h2>
  </div>
</template>

<script>
import gsap from 'gsap'
export default {
  name: 'TickerHeadline',
  props: {
    delay: {
      default: 0
    }
  },
  data () {
    return {
      text: undefined,
      cursorVisible: true,
      visible: false,
      currentIndex: 0,
      currentText: '',
      animationDone: false,
      blinkCount: 0,
      blinkInterval: undefined,
      typeTimeout: undefined

    }
  },
  watch: {
    text () {
      this.$nextTick(() => {
        this.setTextSize()
      })
    },
    '$screen.width' () {
      this.$nextTick(() => {
        this.setTextSize()
      })
    }
  },
  methods: {
    setTextSize () {
      if (!this.$refs.reference) return
      const size = this.$refs.reference.getBoundingClientRect()
      gsap.set(this.$refs.headline, { height: size.height + 'px' })

      this.size = size.height
    },
    toggleCursor () {
      this.cursorVisible = !this.cursorVisible
      if (this.animationDone) {
        this.blinkCount++
        if (this.blinkCount > 3) {
          clearInterval(this.typeTimeout)
          clearInterval(this.blinkInterval)

          this.cursorVisible = true
        }
      }
    },
    startTypeInterval () {
      this.typeTimeout = setTimeout(this.setLetter, 25 + 50 * Math.random())
      this.blinkCount = Math.floor(-3 + Math.random() * 3)
      this.setTextSize()
    },
    setLetter () {
      this.currentIndex++
      this.currentText = this.text.slice(0, this.currentIndex)
      if (this.text !== this.currentText) {
        this.startTypeInterval()
      } else {
        this.animationDone = true
      }
    },
    show () {
      if (this.visible) return
      this.cursorVisible = true
      this.visible = true

      this.blinkInterval = setInterval(this.toggleCursor, 250)
      this.startTypeInterval()
    },
    viewHandler (e) {
      if (!this.text) this.text = this.$slots.default[0].text
      if (e.type === 'enter') {
        setTimeout(() => {
          this.show()
        }, this.delay * 1000)
      }
    }
  },
  mounted () {

  },
  beforeUnmount () {
    clearInterval(this.typeInterval, 500)
    clearInterval(this.blinkInterval, 500)
  }

}
</script>

<style lang="scss">
.ticker-headline {
  position: relative;
  width:100%;
  pointer-events: none;

  h2 {
    width:90%;
  }

  .hidden {
    position: absolute;
    top:-9999999px;
    left:-9999999px;
  }
}
</style>
