
import axios from 'axios'

export default {
  data () {
    return {
      contentJson: undefined,
      content: undefined,
      id: undefined,
      onGetJsonError: undefined,
      baseUrl: '',
      data: undefined
    }
  },
  created: function () {

  },
  mounted: function () {

  },
  methods: {
    onJsonLoaded (data) {
      this.content = data[this.id]
      this.contentJson = data
      this.$store.commit('set_data_json', {
        slug: this.contentJson.slug,
        data: data
      })
    },
    validateSubpage (slug) {
      const found = this.content.find((element) => element.slug === slug)
      return found
    },
    fetchData (data) {
      const baseUrl = '/contents/'

      this.data = data
      this.getJson(this.data, baseUrl, '.json')
    },
    getJson (data, baseUrl, fileSuffix) {
      if (data.split('/')[1] && !this.$store.getters.cachedData(data.split('/')[0])) {
        axios
          .get(baseUrl + data.split('/')[0] + fileSuffix)
          .then((res) => {
            this.$store.commit('set_data_json', {
              slug: res.data.slug,
              data: res.data.data
            })

            axios
              .get(baseUrl + data + fileSuffix)
              .then((res) => this.onJsonLoaded(res.data))
              .catch((err) => { console.log(err); this.onGetJsonError = err.response })
          })
          .catch((err) => { console.log(err.response); this.onGetJsonError = err.response })
      } else {
        // if (this.contentJson) return this.contentJson; //ABGLEICH MIT STORE?

        if (this.$store.getters.cachedData(data)) {
          this.contentJson = this.$store.getters.cachedData(data)
          this.content = this.contentJson[this.id]
        }
        this.id = data
        // PROMISE
        axios
          .get(baseUrl + data + fileSuffix)
          .then((res) => this.onJsonLoaded(res.data))
          .catch((err) => { console.log(err); this.onGetJsonError = err.response })
      }
    }
  },
  watch: {
    onGetJsonError (response) {
      if (response.status === 404) {
        //
      }
    }
  }
}
